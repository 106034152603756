.formModal_bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.303);
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  display: none;
}

.formModal_bg.hide {
  opacity: 1;
  display: block;
}
.formModal {
  position: absolute;
  max-width: 500px;
  height: auto;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  animation: fadeIn 0.5s ease-in-out;
  box-shadow: 0 1px 2px rgba(48, 47, 47, 0.467);
}

.formModal > i {
  float: right;
  margin: 5px 10px;
  font-size: 20px;
  cursor: pointer;
}
.form_modal {
  padding: 30px;
}

.form_modal_btn {
  display: block;
  width: 150px;
  height: 50px;
  margin: 0 auto;
  border-radius: 5px;
  border: none;
  background-color: #ed0d0d;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}
.form_modal_btn:hover {
  background-color: #c01616;
}
.form_modal_btn > i {
  padding-right: 5px;
}

.form_btn_grp {
  display: flex;
  width: 100%;
  height: auto;
  margin: 30px 0;
}
.form_btn_grp > button {
  margin: 0 auto;
  width: 40%;
  height: 50px;
  border: none;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.form_btn_grp > .submit {
  background-color: #252522;
  color: #fff;
  border-radius: 3px;
}
.form_btn_grp > .discard {
  background-color: #ced4da;
  color: #252525;
  border-radius: 3px;
}
.form_btn_grp > .submit:hover {
  background-color: #00a14e;
}
.form_btn_grp > .discard:hover {
  background-color: #ed0d0d;
  color: #fff;
}
.css-1wnsr1i {
  border: none !important;
  border-radius: 4px;
}

.err {
  font-size: 13px;
  margin-top: 5px;
  color: rgba(237, 19, 19, 0.958);
  font-weight: 600;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.err.show {
  opacity: 1;
}
