.transaction_main {
  background-color: #eee;
  min-height: 100%;
}
.card_transaction {
  background-color: #fff;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 5px !important;
}
.loader_ {
  display: block;
  margin: 0 auto;
}
.puff_loader {
  margin: 0 auto;
}
