.card-subscription{
    margin-left: 290px;
    margin-top: 20px;
}
@media screen and (max-width: 537px) {
    .card-subscription {
        margin-left: 0px;
    }
  }
  .btn-new{
    width: 100px;
    margin-top: 20px;
    float: right;
    margin-right: 20px;
  }
  .card .card-style{
    margin-right: 30px;
  }