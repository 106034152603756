@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #747474;
}
* {
  font-family: "Poppins", sans-serif;
}

.save_btn {
  margin: 8px;
}

.settings_card {
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 245);
}
.tabss {
  font-weight: 600 !important;
  font-size: 18px !important;
  font-family: "Poppins", sans-serif !important;
  width: 20%;
}
.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #343a40 !important;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  border: none !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}
.credit_txt:active,
.credit_txt:focus {
  border-color: #ff000067;
}
.gift_txt:active,
.gift_txt:focus {
  border-color: #00a3fbd2;
}
.err {
  font-size: 13px;
  margin-top: 5px;
  color: rgba(237, 19, 19, 0.958);
  font-weight: 600;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.err.show {
  opacity: 1;
}
.credit_card {
  border-radius: 2px;
  padding: 5px 7px;
  box-shadow: 0 1px 2px rgba(48, 47, 47, 0.467);
}

.credit_card .card-body > p {
  padding: 5px 0;
  margin: 0;
  font-size: 18px;
}
.credit_card .card-body p > span {
  font-weight: 600;
}
.credit_btn {
  display: block;
  background-color: #ed0d0d;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  margin: 0 auto;
  text-align: center;
  border: none;
}
.credit_btn:hover {
  color: #fff;
  background-color: #c01616;
}
.credit_card.disabled {
  opacity: 0.5;
  user-select: none;
  background-color: #eee;
}
.formFile {
  overflow: hidden;
}
#formFile {
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
#formFile::file-selector-button {
  margin-right: 10px;
  border: none;
  background: #ed0d0d;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  padding: 2px 15px;
}

#formFile::file-selector-button:hover {
  background: #c01616;
}
.gift_form_btn {
  display: block;
  width: 150px;
  height: 50px;
  margin: 0 auto;
  border-radius: 5px;
  border: none;
  background-color: #ed0d0d;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}
.gift_form_btn:hover {
  background-color: #c01616;
}
.gift_form_btn > i {
  padding-right: 5px;
}
.gift_section {
  padding: 30px 0;
}
.gift_icon {
  width: 30px;
  height: 30px;
}
.gift_card {
  position: relative;
  border-radius: 0px;
  padding: 5px 7px;
  height: 180px;
  box-shadow: 0 1px 2px rgba(48, 47, 47, 0.467);
}

.gift_card .card-body > p {
  padding: 5px 0;
  margin: 0;
  font-size: 18px;
}
.gift_card .card-body p > span {
  font-weight: 600;
}
.gift_card .card-body p span > img {
  margin-left: 10px;
}
.gift_btn_grp {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.gift_btn_grp > button {
  margin: 0 5px;
  padding: 2px 5px;
  border: none;
  background: transparent;
  outline: none;
  color: rgba(83, 83, 83, 0.611);
  transition: all 0.2s ease-in-out;
}
.edit:hover,
.delete:hover {
  color: #252525;
}
.editModal_bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.303);
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  display: none;
}
.editModal_bg.hide {
  opacity: 1;
  display: block;
}
.editModal {
  position: absolute;
  max-width: 500px;
  height: 450px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  animation: fadeIn 0.5s ease-in-out;
  box-shadow: 0 1px 2px rgba(48, 47, 47, 0.467);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.editModal > i {
  float: right;
  margin: 5px 10px;
  font-size: 20px;
  cursor: pointer;
}
.gift_form {
  padding: 40px;
}
.form_btn_grp {
  display: flex;
  width: 100%;
  height: auto;
  margin: 30px 0;
}
.form_btn_grp > button {
  margin: 0 auto;
  width: 40%;
  height: 50px;
  border: none;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.form_btn_grp > .save {
  background-color: #252522;
  color: #fff;
  border-radius: 3px;
}
.form_btn_grp > .discard {
  background-color: #ced4da;
  color: #252525;
  border-radius: 3px;
}
.form_btn_grp > .save:hover {
  background-color: #00a14e;
}
.form_btn_grp > .discard:hover {
  background-color: #ed0d0d;
  color: #fff;
}
.css-1wnsr1i {
  border: none !important;
  border-radius: 4px;
}
