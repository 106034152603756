.member-card {
  margin-left: 280px;
  margin-top: 50px;
}
@media screen and (max-width: 537px) {
  .member-card {
    margin-left: 0px;
  }
}
.badge-ban {
  height: 26px;
  margin-right: 10px;
  font-size: 15px;
  width: 70px;
}

/* Modal Styla */
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.407);
  backdrop-filter: saturate(50%) blur(7px);
  z-index: 999;
}
.modal-container {
  position: absolute;
  width: 800px;
  height: 680px;
  background-color: #ffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  padding: 20px;
  animation: show 0.5s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.ban-modal {
  height: 400px;
}
.suspend-modal {
  height: 450px;
}
.ban_btn {
  display: block;
  margin: 0 auto;
  width: 40%;
}
@keyframes show {
  from {
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.close {
  cursor: pointer;
}
.modal-container > h2 {
  text-align: center;
  padding: 10px 0;
}
.form_btn {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.modal-container > .profile {
  width: 120px;
  height: 120px;
  background-color: #eee;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}
.profile > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.view_input {
  background-color: #fff !important;
  border-color: #eee;
}
.active_ {
  position: absolute;
  width: 60px;
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  right: 20px;
  user-select: none;
  font-size: 13px;
}
.active_.green {
  background-color: #188351;
}
.active_.red {
  background-color: #eb3941;
}
.datePicker {
  margin-left: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.datePicker > p {
  cursor: pointer;
}
.calander {
  position: absolute;
  top: 10%;
  right: 20px;
  display: none;
}
.calander.show {
  display: block;
}

@media screen and (max-width: 1200px) {
  .searchbar {
    width: 50% !important;
  }
  .card-body {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 786px) {
  .searchbar {
    width: 40% !important;
  }
}

/* -----------------VIEW MEMBER PAGE--------------- */

.dash_card {
  padding: 10px 20px;
  border: 1px solid #c9c9c9ee;
  box-shadow: 0 0 0 transparent;
  min-height: 200px;
  height: 280px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
}
.subscription{
  padding: 10px 20px;
  border: 1px solid #c9c9c9ee;
  box-shadow: 0 0 0 transparent;
  min-height: 200px;
  height: 280px;
  width: 100%;
  overflow: hidden;
}
.member_name {
  overflow: hidden;
  white-space: nowrap;
  font-size: 25px;
  text-overflow: ellipsis;
}
.member_nick {
  font-size: 15px;
  font-weight: 500;
}
.profile-image {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.profile-image > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.subject {
  padding-left: 260px;
}
.live {
  position: relative;
}
.rounded-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 20px;
  position: relative !important;
}
.live-dot {
  background-color: #ff5252;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  margin-top: 3px;
}
@keyframes pulse-black {
  0% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.854);
  }

  70% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0.186);
  }

  100% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.notify-live {
  position: absolute !important;
  top: 20%;
  font-size: 10px;
  background-color: #eb3941;
  color: white;
  width: 30px;
  border-radius: 25px; 
  text-align: center;
  margin: 5px;
  padding: 2px;
  text-decoration: blink;
  cursor: pointer;
}
.user-details {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.cate {
  margin-top: 10px;
}
.span-user {
  font-weight: 400;
}
.card-head {
  font-size: 20px;
  color: #747474;
}
.cate-list {
  user-select: none;
  background-color: #8a8a8a;
  color: white;
  display: inline;
  font-size: 12px;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 15px;
}
.cur-sub {
  text-align: center;
  font-size: 16px;
  color: #252525;
}
.planned-sub {
  color: #eb3941;
  font-size: 700;
  text-decoration: wavy;
}
.card-sub {
  margin-bottom: 5px;
  text-align: center;
  padding: 0 10px;
}
.break-here::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 40px;
  background-color: #c4c4c4;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.content-sub {
  font-size: 16px;
  font-weight: 600;
}
.content {
  font-weight: 500;
  font-size: 14px;
}
.para {
  text-align: center;
  font-size: 12px;
}
.gift-an {
  height: 100px;
}
.dash-cd {
  height: 100%;
}
.but-btn {
  background-color: #eb3941;
  color: white;
  padding: 5px;
  margin: 2px;
  outline: none;
  border: none;
  width: 90px;
  border-radius: 12px;
}
.nav-link {
  color: #eb3941;
}
.nav-link:hover {
  color: #c9363d;
} 
.content_wrapper {
  position: relative;
}
.dash_img {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  background-image: url("../../image/propic.jpg");
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.dash_img > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.dash_img:hover {
  border-color: #cacaca;
}
.dash_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.192);
  backdrop-filter: blur(2px);
  z-index: 8;
  opacity: 0;
}
.dash_img:hover::before{
  opacity: 1;
}
.dlt_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 99;
}
.btn-success{
 margin-right: 5px;
}

/* .table>:not(caption)>*>* {
border: none;
} */

.dlt_btn > button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 16px 20px;
  border-radius: 50%;
  color: #c9363d;
  font-size: 20px;
}
.dash_img:hover > .dlt_btn{
  opacity: 1;
}
.dash_video{
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 5px;
}
.error_msg{
  font-size: 18px;
  color: #eee;
  text-align: center;
  margin-top: 10%;
  user-select: none;
  font-weight: 600;
}
.full_page{
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1550px) {
  .profile-image {
    width: 130px;
    height: 130px;
  }
  .card-head{
    font-size: 16px;
  }
  .credit{
    font-size: 14px;
  }
  .point{
    font-size: 16px;
  }
}
@media screen and (max-width: 1300px) {
  .profile-image {
    width: 100px;
    height: 100px;
  }
  .gift-an {
    height: 70px;
  }
  .card-head{
    font-size: 16px;
  }


}
@media screen and (max-width: 1200px) {
  .user-mgt {
    padding-left: 10px;
  }
  .member_name {
    font-size: 16px;
    width: 100%;
  }
  .member_nick {
    font-size: 13px;
  }
  .user-details {
    font-size: 12px;
  }
  .cate {
    padding: 21px 0;
    overflow-x: scroll !important;
    overflow: hidden;
  }
  .cate-list {
    font-size: 10px;
  }
  .cate::-webkit-scrollbar {
    height: 0.2em;
  }

  .cate::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  .cate::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);
    outline: 1px solid rgb(145, 147, 150);
  }
  .credit{
    font-size: 14px;
  }
  .point{
    font-size: 16px;
  }
  .subscription{
    overflow-y: scroll !important;
  }
  .subscription::-webkit-scrollbar {
    height: 5px !important;
    width: 5px;
  }
  
  .subscription::-webkit-scrollbar-track {
    background-color: #FFF;
  }
  
  .subscription::-webkit-scrollbar-thumb {
    background-color: rgb(201, 190, 190);
  }

}

@media screen and (max-width: 991px) {
  .subject {
    padding: 5px;
  }
  .but-btn {
    margin-bottom: 10px;
  }
  .table{
    font-size: 12px;
   
  }
  .card-det{
    overflow-x: scroll !important;
  }
  .card-det::-webkit-scrollbar {
    height: 5px !important;
    width: 5px;
  }
  
  .card-det::-webkit-scrollbar-track {
    background-color: #FFF;
  }
  
  .card-det::-webkit-scrollbar-thumb {
    background-color: rgb(201, 190, 190);
  }
  .vjs-icon-placeholder{
    width: 30%;
    height: 25%;
  }
}
@media screen and (max-width: 786px) {
 .row-block{
  display: block;
 }
.card-pro{
  text-align: center;
  align-items: center;
  margin: 0px auto;
}
.details-area{
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.game-card{
  overflow-y: scroll;

}
.game-card::-webkit-scrollbar {
  height: 5px !important;
  width: 5px;
}

.game-card::-webkit-scrollbar-track {
  background-color: #FFF;
}

.game-card::-webkit-scrollbar-thumb {
  background-color: rgb(201, 190, 190);
}
.table>:not(caption)>*>* {
  border: none;
  margin-top: 63px;
}

}
@media screen and (max-width: 600px) {
  .dash_card {
    margin-bottom: 10px;
  }
  .card-pro{
    margin: 0px auto;
  }
  .detailed-card{
    align-items: center;
    margin: 0px auto;
  }
 .profile-image{
  margin: 0px auto;
  position: relative;
 }
 .notify-live{
  position:absolute;
  left: 35%;;
  margin: 0px auto;
 }
 .user-mgt{
  margin: 0px auto;
  text-align: justify;
  place-items: center;
 }
 .gift{
  height: auto;
 }
 .dash_card{
  height: auto;
 }
 .game-card{
  margin-bottom: 20px !important;
 }
 
}

.member_live_indication{
  cursor: auto;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
}