.adModal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.418);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: block;
}
.adModal_wrapper.hide {
  display: none;
}

/* videoAds */
.videoAdCard {
  padding: 30px;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: hidden;
}
.videoAdCard > h4 {
  font-size: 20px;
  font-weight: 500;
  color: #252525;
}
.upload_btn {
  width: 100%;
  margin-top: 5px;
  background-color: #d41415;
  color: #fff;
}
.preview-player {
  aspect-ratio: 16/9;
  overflow: hidden;
  background-color: #000;
}

.prview_close {
  position: absolute;
  color: #ffff;
  z-index: 99;
  background-color: transparent;
  right: 10px;
  border: none;
  outline: none;
}
.videoAdCard > label {
  font-weight: 400 !important;
  font-size: 15px;
}
.tooltip_ {
  position: absolute;
  width: 50%;
  height: auto;
  background-color: #eee;
  z-index: 99;
  top: 20px;
  right: 0;
  padding: 10px;
  font-size: 12px;
  box-shadow: 0 2px 5px #7777;
  transition: all 0.5s ease-in-out;
}
.tooltip_.hide {
  display: none;
}
.checks {
  transition: all 0.5s ease-in-out;
}
.tooltip_2,
.tooltip_3 {
  width: 100%;
  position: absolute;
  bottom: -40px;
  background-color: #eee;
  padding: 5px 20px;
  border-radius: 4px;
  transition: all 0.5 ease-in-out;
  opacity: 0;
  font-size: 15px;
}
.cheks:hover .tooltip_2 {
  opacity: 1;
}
.cheks2:hover .tooltip_3 {
  opacity: 1;
}
.ant-btn-primary{
  background-color: #d41415;
  transition: all 0.2s ease-in-out;
}
.ant-btn-primary:hover{
  background-color: #c21313 !important;
}
.ant-btn-default:hover{
  border-color: #c21313 !important;
  color: #c21313 !important;
}