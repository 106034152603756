.live_cards {
  /* position: relative; */
  width: 100%;
  height: 220px;
  box-shadow: none;
  border: 1px solid rgb(225, 224, 224);
  font-size: 15px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 0;
  box-sizing: border-box;
}
.EmojiPickerReact {
  width: 100% !important;
}
.live_cards.show {
  position: relative;
  min-height: 300px;
  z-index: 1;
}
.live_cards:hover {
  box-shadow: 0 2px 3px #e5e5e5;
}
.live_cards label {
  padding-right: 10px;
}
.live-cont > p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}
.content-wrapper {
  min-height: 100%;
  background-color: rgb(238, 238, 238);
  padding-bottom: 30px;
}
.live_status {
  padding: 5px;
  background-color: #ee4744;
  color: #fff;
  border-radius: 2px;
  user-select: none;
  width: 15%;
  margin-right: 10px !important;
  text-align: center;
}
.description_live {
  position: relative;
}
.read_more {
  height: 20px;
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  font-size: 12px;
  white-space: nowrap;
  margin: 15px;
}
.desc {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.desc.show {
  height: 100%;
  min-height: 200px;
  text-overflow: unset;
  overflow: inherit;
  overflow-y: scroll;
}
.desc::-webkit-scrollbar {
  width: 5px;
}

.desc::-webkit-scrollbar-track {
  background-color: #eee;
}

.desc::-webkit-scrollbar-thumb {
  background-color: #ee4744;
}
.live_btn {
  position: absolute;
  padding: 7px 15px;
  border: none;
  background-color: #198754;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #198754;
  bottom: 10px;
}
.live_btn:hover {
  color: #fff;
}
.subjects {
  padding-left: 60px;
  padding-right: 60px;
}
.liveScreen {
  aspect-ratio: 16/9;
  height: 100%;
  padding: 30px 0;
}
.live_card {
  width: 100%;
  padding: 20px;
  min-height: 250px;
  background-color: white;
  font-size: 15px;
  overflow: hidden;
  color: black;
  transition: all 0.2s ease-in-out;
}
.live-continer {
  padding: 0 !important;
  margin: 0 !important;
}
.live_card .live-cont > p {
  color: black;
  font-weight: 400;
}
.live_card .live-cont > label {
  padding-right: 10px;
  font-weight: 500;
}
/* @media screen and (max-width:786) {
  .
} */
#left {
  margin-left: 10px;
}
.live-continer.hide {
  display: none;
}
.chat-head {
  background-color: white;
  border-radius: 6px;
}
/* imported */
.sent-box {
  display: block;
  margin: 0px auto;
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  bottom: 0;
  overflow: hidden;
}
.sent-box input {
  width: 90%;
  outline: none;
  height: 47px;
  font-size: 15px;
  border: none;
  background-color: transparent;
}
.picker-stick {
  background-color: #fff;
  width: 100% !important;
  min-width: 100%;
}
.chat-right {
  width: 100%;
  float: right;
}
.chat-right > .txt {
  margin-left: auto;
  margin-right: 3px;
  padding-top: 20px;
}
.type_area {
  margin-bottom: 0;
  width: 80%;
  height: 63px !important;
  overflow: hidden;
  height: 50px;
  width: 100%;
}

.chat_head {
  width: 100% !important;
  display: flex;
  position: relative;
  padding: 0 !important;
}
.chat_head > h2 {
  color: var(--secondary-color);
}
.chat_head > p {
  color: #b2b2b4;
  padding: 10px;
}
.chat_head > button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: var(--secondary-color);
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
}
.chat_head > button:active {
  background-color: rgb(208, 208, 208);
}
.chat_area {
  min-height: 520px;
  width: 100%;
}
.chat_info {
  width: 100%;
  height: 75px;
  background-color: #249782;
  color: white;
  padding: 20px;
  position: relative;
  border-radius: 12px;
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400 !important;
}
.chat_info > h5 {
  color: white;
  font-size: 12px;
  text-align: center;
}
.chat_info > h6 {
  font-size: 12px;
  padding: 0 10px;
  color: white;
  text-align: center;
}
.chat_info > i {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
}
.chat {
  position: relative;
  width: 100%;
  padding: 20px 0px;
}
.chat_area_left {
  height: 530px;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.chat_area_left::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.chat_area_left::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.chat_area_left::-webkit-scrollbar-thumb {
  background-color: #d4bcbc;
  outline: #a28f8f;
}

.avt {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f2f3f7;
  padding: 3px;
  max-width: 100%;
  text-align: center;
}
.chat_member {
  font-size: 12px;
  line-height: 10px;
  margin-bottom: 0px;
  font-weight: 600;
}
.avatarpng {
  width: 35px;
  height: 35px;
}
.avt > img {
  max-height: 100%;
}
.txt > .txt_cont {
  width: 100%;
  padding: 5px;
  color: black;
  background-color: #fff;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  /* border-radius: 12px 0px 12px 12px; */
  border-radius: 5px;
  font-weight: 500;
}

.txt > .txt_conts {
  width: auto;
  color: black;
  padding: 12px 20px;
  background-color: #e4e8f1;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  border-radius: 12px 12px 0px 12px;
  font-weight: 500;
}
.txt_cont.right {
  border-radius: 12px 0px 12px 12px;
}
.txt_cont.left {
  border-radius: 0px 12px 12px 12px;
}
#right {
  margin: 0 10px;
}
.type_area {
  margin-bottom: 0;
  width: 80%;
  overflow: hidden;
  height: 50px;
  width: 100%;
}
hr {
  margin: 0;
}
.chat-heading {
  font-size: 16px;
  text-align: center;
  background-color: #383838;
  margin: 0px auto;
  padding: 5px;
  text-align: left;
  color: white;
}
.chat-heading h3 {
  font-weight: 400;
}
@media screen and (max-width: 1250) {
  .content-wrapper {
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .subjects {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 786px) {
  .live_card {
    height: auto;
    width: auto;
    padding: 5px;
  }
}

.emoji-btn {
  position: absolute;
  border: none;
  outline: none;
  float: right;
  color: #ee4744;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background-color: transparent !important;
}
.stop-stream {
  margin: 0px auto;
  text-align: center;
}
.lot {
  width: 200px !important;
  height: 200px;
}
.participants {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #525353;
  color: white;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  margin-right: 5px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-top: 5px;
}
.participants:hover {
  background-color: #383838;
}
.profile-im {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.datas- {
  line-height: 0.5rem;
  margin-left: 10px;
  font-weight: 500;
}
.title {
  color: #525353;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.read-more {
  outline: none;
  border: none;
  font-size: 14px;
  background-color: transparent;
}
.read-less {
  outline: none;
  background-color: transparent;
  border: none;
  background-color: transparent;
  color: #525353;
  padding: 3px;
  height: 33px;
  margin: 5px;
  word-wrap: normal;
}
.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}
/* .button_grp{
  position: absolute;
  right: 0;
} */
.eye {
  background-color: transparent;
  color: #525353;
}
.eye > span {
  padding-left: 3px;
  font-size: 14px;
}
.username {
  margin-top: 10px;
}
.nick {
  color: #525353;
  line-height: 1rem;
  font-size: 14px;
}
.btn2_grp > button {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  margin-left: 4px;
}
.report_ {
  height: 40px;
  width: 20px;
  border-radius: 50%;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
  color: #ee4744;
}
.report_:hover {
  background-color: #f9f9f9;
}
.report_:active {
  background-color: #eee;
}
.chat_main {
  height: 630px;
  position: relative;
  background: #f2f2f2;
  max-height: 857px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 30px;
}
aside.EmojiPickerReact.epr-main {
  position: absolute !important;
  float: right !important;
  bottom: 0 !important;
  right: 0 !important;
}
.ban-btn:hover {
  background-color: #ee4744;
}
.suspend-btn:hover {
  background-color: #249782;
}
#modal_header {
  font-size: 16px;
  font-weight: 600;
  color: #6c757d;
  line-height: 0.5rem;
}
#modal_title {
  font-size: 22px;
  font-weight: 600;
}
.action-btn {
  padding: 7px 20px;
  margin-right: 5px;
  border: none;
  outline: none;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #383838;
}
.danger:hover {
  background-color: #ee4744;
}
.cancel_:hover {
  background-color: #6c757d;
}
.card-live {
  width: 28px;
  border-radius: 50%;
  right: 10px;
  top: 10px;
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  .desc {
    font-size: 13px;
  }
  .chat_area_left {
    min-height: 630px;
  }
}
