/* @media screen and (max-width: 1200px){
    .video-js .vjs-big-play-button {
      position: absolute;
      top: 67%;
      left: 65%;
      transform: translate(-50%, -50%);
    } 
}
@media screen and (max-width: 786px){
  .video-js .vjs-big-play-button {
    position: absolute;
    top: 73%;
    left: 70%;
   width: 100px;
    transform: translate(-50%, -50%);
  } 
} */
.vjs-error .vjs-error-display .vjs-modal-dialog-content {
  font-size: 1.4em;
  text-align: center;
}
.vjs-big-play-button {
  text-align: center;
  margin: 0px auto !important;
  position: absolute;
  transform: translate(-50%);
}
.video-js {
  position: relative;
  width: 100%;
  min-width: 100%;
}

.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 10em;
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-control {
  width: 3em;
}

.video-js .vjs-menu-button-inline:before {
  width: 1.5em;
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em;
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button {
  display: block;
}

.video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-mouse-display:after,
.video-js .vjs-play-progress:after {
  padding: 0 0.4em 0.3em !important;
}

.video-js.vjs-ended .vjs-loading-spinner {
  display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important;
}

video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
  display: block;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus,
.video-js .vjs-big-play-button:active {
  background-color: null;
}

.video-js .vjs-loading-spinner {
  border-color: null;
}

.video-js .vjs-control-bar2 {
  background-color: #000000;
}

.video-js .vjs-control-bar {
  background-color: #000000af !important;
  color: null;
  font-size: 10px;
}

.video-js .vjs-play-progress,
.video-js .vjs-volume-level {
  background-color: null;
}
