
.item {
  align-items: center;
  justify-content: center;
  margin: 5px;
}
.pag-table{
  position: relative;
}

.pagination {
  align-items: center !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}

.pagination-page {
  font-weight: 700;
}
.page-cont{
  align-items: center;
  justify-content: center;
}
.content-wrapper{
  background-color: rgb(253, 253, 253);
}

.item.active {
 color: white;
 width: 23px;
 height: 23px;
 font-weight: 500;
 margin: 3px;
 border-radius: 50%;
 text-align: center;
 background-color: #252525;
}