.red-msg {
    font-size: 30px;
    font-weight: 600;
    margin-top: 170px;
  }
  .msg-para {
    text-align: justify;
    margin-top: 50px;
  }
  .lot-svg {
    margin-top: 170px;
  }
  .redirect {
    background-color: #252525;
    color: white;
    width: 200px;
    height: 50px;
    margin-top: 52px;
    text-align: center;
    z-index: 1;
    border: none;
    outline: none;
    left: 50%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 50px;
    border-radius: 3px;
  }
  .redirect:hover {
    background-color: #c4d4bc;
    color: black;
    font-weight: 600;
  }
  
  .redirect::after,
  .redirect::before {
    background-color: #c4d4bc;
    content: "";
    -webkit-transition: all 0.35s ease-in-out 0s;
    -moz-transition: all 0.35s ease-in-out 0s;
    -ms-transition: all 0.35s ease-in-out 0s;
    -o-transition: all 0.35s ease-in-out 0s;
    transition: all 0.35s ease-in-out 0s;
  }
  .redirect::before {
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    z-index: -1;
    background-color: #c4d4bc;
    -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transition: all 0.35s ease-in-out 0s;
    -moz-transition: all 0.35s ease-in-out 0s;
    -ms-transition: all 0.35s ease-in-out 0s;
    -o-transition: all 0.35s ease-in-out 0s;
    transition: all 0.35s ease-in-out 0s;
  }
  
  .redirect:hover::before {
    background-color: #c4d4bc;
    opacity: 1;
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  .pnf-img {
    width: 100%;
    margin-top: 50px !important;
  }
  .grn {
    color: #c4d4bc;
  }
  .imagearea {
    width: 500px;
    height: 400px;
    margin: 0px auto !important;
    text-align: center;
  }
  .pnf-head {
    text-align: center;
    font-weight: 600;
    margin-bottom: 50px !important;
  }
  .back-nav {
    margin: 0px auto;
  }
  .pnotf {
    background-color: #fff;
  }
  /* 786,500/600 */
  @media screen and (max-width: 991px) {
    .red-row {
      display: block;
    }
  }
  