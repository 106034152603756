* {
  margin: 0;
  padding: 0;
}
.page_loading{
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.196);
  backdrop-filter: saturate(100%) blur(5px);
  z-index: 99;
  transition: all 1s ease-in-out;
}
.loader{
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}
.filter1 {
  width: 100%;
}
.filter_grp {
  margin-left: 20px;
  padding: 10px;
}
.filter_grp > .filter_btn {
  margin: 10px;
  padding: 5px 15px;
  border: none;
  outline: none;
  border-radius: 5px;
}
.filter_btn.active {
  background-color: #ea110d;
  color: #fff;
}
/* .payouts {
  position: relative;
  height: 100vh;
} */

.payout_cont {
  width: 100%;
  height: auto;
  padding: 30px 0;
}
.card.pay_cards {
  width: 100%;
  height: 180px;
  border: 1px solid #d03c1e5b;
  padding: 10px;
}
.toggle_data {
  list-style: none;
  padding: 10px;
}
.row_value.hide {
  display: none;
}
.table {
  border: none !important;
}
.main_thead {
  background-color: #343a40;
  color: #fff;
}
.main_data {
  background-color: #eee;
  transition: all 0.2s ease-in-out;
}
.main_data > td {
  user-select: none;
}
.main_data:hover {
  background-color: #e9e9e2;
}
.sub_table {
  border: 2px solid #d6d6d6 !important;
  box-shadow: 0 2px 3px #eee;
  background-color: rgba(233, 233, 233, 0.467);
}
.payout_cont::-webkit-scrollbar {
  height: 5px;
}

.payout_cont::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.payout_cont::-webkit-scrollbar-thumb {
  background-color: #a7a7a5;
  outline: 1px solid transparent;
}
.btn_grp > button {
  border: none;
  padding: 7px 16px;
  color: #fff;
  margin-left: 5px;
  border-radius: 5px;
}
.success {
  background-color: #228b22;
}
.success > i {
  font-size: 20px;
}
.info {
  background-color: #2899e5;
}
.info > i {
  font-size: 20px;
}
.warm {
  background-color: #ff8c00;
}
.warm > i {
  font-size: 20px;
}
.cancel {
  background-color: #ff220e;
}
.date {
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  user-select: none;
}
.date:hover,
.date:active {
  background-color: #eee;
}

@media screen and (max-width: 992px) {
  .payout_cont {
    overflow-x: scroll;
  }
}
