body:not(.layout-fixed) .main-sidebar{
    position: fixed;
}
.mob-nav {
  position: fixed;
  width: 100px;
  height: 100%;
  background-color: #343a40;
  z-index: 2;
  top: 0;
  left: 0;
}
.logo-area {
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.logo-area > img {
  max-width: 100%;
}
.nav-list {
  margin: 10px;
  list-style: none;
  padding: 10px;
}
.nav-list > .nav-items {
  padding: 5px 0;
  margin: 1rem;
}
.nav-list .nav-items > a {
  text-decoration: none;
  color: #70777e;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.nav-items:hover > a {
  color: #fff;
}
.nav-items::after,
.nav-items::before {
  position: absolute;
  right: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.nav-items::before {
  content: "";
  border-width: 5px 4px 0 5px;
  border-style: solid;
  border-color: rgba(56, 56, 56, 0.8) transparent;
  top: 50%;
}
.nav-items::after {
  content: attr(data-tooltip);
  width: auto;
  margin-left: 10px;
  padding: 5px;
  font-size: 15px;
  background-color: #343a40;
  color: #fff;
  border-radius: 4px;
  transform: translate3d(0, 50%, 0);
  pointer-events: none;
}
.nav-items::before {
  margin-left: 14px;
  top: 12px;
  transform: rotate(90deg);
}

.nav-items::after {
  transform: translate3d(110%, 0, 0);
}
.nav-items:hover::before,
.nav-items:hover::after {
  opacity: 1;
}
.selected {
  color: #007bff !important;
}
.bars {
  width: 20px;
  height: 20px;
  position: relative;
  left: 15%;
  top: 10px;
  z-index: 10;
  display: none;
}
.bars > input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

@media screen and (max-width: 991px) {
  .main-sidebar {
    width: 0px;
  }
  .main-header {
    z-index: 1;
  }
  .bars{
    display: block;
  }
  .mob-nav {
    width: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }
  .mob-nav.show{
    width: 100px;
    overflow: hidden;
  }
  
}
.nav-sidebar .nav-link p{
  white-space: nowrap !important;
}